<template>
  <router-view />
</template>

<script>
import roleModule from '../../store/roles'

export default {
  beforeCreate () {
    if (!this.$store.hasModule('roles')) {
      this.$store.registerModule('roles', roleModule)
    }
  },
  destroyed () {
    if (this.$store.hasModule('roles')) {
      this.$store.unregisterModule('roles')
    }
  }
}
</script>
