<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Role</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors, 'name')" :state="validationState(errors, 'name')">
                <b-form-input :disabled="isSaving" v-model="name" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <h2>Permissions</h2>
            </b-col>
            <b-col cols="12">
              <select-permissions @update="onPermissionsChange" :permissions="permissions"></select-permissions>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cold="12" offset-md="8" md="4">
              <b-button block :disabled="isSaving" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import flatMap from 'lodash/flatMap'
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import SelectPermissions from '../../components/SelectPermissions.vue'

export default {
  mixins: [validation],
  created () {
    if (this.$route.params.id) {
      this.fetchRole(this.$route.params.id)
    }
  },
  components: {
    SelectPermissions
  },
  computed: {
    ...mapGetters('roles', ['errors', 'isLoading', 'isSaving', 'single'])
  },
  data () {
    return {
      name: null,
      permissions: []
    }
  },
  methods: {
    ...mapActions('roles', ['fetchRole', 'saveRole']),
    onPermissionsChange ({ permissions, roles }) {
      this.permissions = permissions
    },
    submit () {
      this.saveRole({
        id: this.$route.params.id,
        name: this.name,
        permissions: this.permissions
      }).then(response => {
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: `Role ${this.$route.params.id ? 'Updated' : 'Created'} Successfully`,
          text: 'This role has successfully been saved.'
        })

        if (!this.$route.params.id) {
          this.$router.push({ name: 'admin.roles.update', params: { id: response.data.id } })
        }
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: `Error ${this.$route.params.id ? 'Updated' : 'Created'} Role`,
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    }
  },
  watch: {
    single (value) {
      this.name = value.name
      this.permissions = flatMap(value.permissions, 'name')
    }
  }
}
</script>

<style>

</style>
